function Validation(values) {
    let errors = {}

    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; //email validation regex
    const password_pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/; //password validation regex

    //check if name is empty
    if (values.name === "") {
        errors.name = "Name should not be empty";
    }

    //name is valid
    else {
        errors.name = "";
    }

    //check if email is empty
    if (values.email === "") {
        errors.email = "Email should not be empty";
    }

    //check if email is valid
    else if (!email_pattern.test(values.email)) {
        errors.email = "Invalid email format";
    }

    //email is valid
    else {
        errors.email = "";
    }

    //check if password is empty
    if (values.password === "") {
        errors.password = "Password should not be empty";
    }

    //check if password is valid
    else if (!password_pattern.test(values.password)) {
        errors.password = "Password must be 8+ characters,\n 1+ uppercase letter,\n 1+ lowercase letter, and 1+ number."
    }

    //password is valid
    else {
        errors.password = "";
    }

    return errors;

}

export default Validation;
