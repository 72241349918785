import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faPlus, faDumbbell } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
// import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Workout.css';
import './images/workout.png'

export const Workout = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const loggedIn = window.localStorage.getItem("isLoggedIn");
    const userName = window.localStorage.getItem('userName');
    const Email = window.localStorage.getItem('Email');
    const userID = window.localStorage.getItem('userID');
    const [workout, setWorkout] = useState([]);
    const effectRan = useRef(false);

    const [isDropDownVisible, setIsDropDownVisible] = useState(false);
    const dropDownRef = useRef(null);
    const profileIconRef = useRef(null);

    const navigate = useNavigate();
     //toggles drop down of profile icon
     function toggleDropDown() {
        setIsDropDownVisible(!isDropDownVisible);
    }

    //clears local storage on log out
    function handleLogOut() {
        window.localStorage.clear();
    }
    
    //check if user is logged in
    let isLoggedIn;
    if (loggedIn === "true") {
        isLoggedIn = true;
    }

    else if (loggedIn === "false") {
        isLoggedIn = false;
    }

    const backToDashboard = (e) => {
        e.preventDefault();
        navigate('/dashboard');
    }

    const handleMuscleClick = (muscle) => {
        navigate(`/workout/${muscle}`);
      };

    useEffect(() => {
        document.title = "TimeCoach | Workout";
        if (!isLoggedIn) {
            navigate('/login');
        }

        //handle clicks outside of profile icon
        const handleClickOutside= (event) => {
            //checks if click is outside of profile icon
            if (dropDownRef.current && !dropDownRef.current.contains(event.target) && event.target !== profileIconRef.current) {
                //sets dropdown visibility to false
                setIsDropDownVisible(false);
            }
        }

        if (effectRan.current === false) {
            const fetchWorkout = async () => {
                try {
                  const response = await axios.get(`${API_URL}/workout/muscle/${userID}`);
                  setWorkout(response.data);
                } catch (error) {
                  console.error('Error fetching workouts:', error);
                }
              };

              fetchWorkout();
        }
            effectRan.current = true;
            // Add event listener when component mounts
            document.addEventListener('mousedown', handleClickOutside);

            // Cleanup by removing event listener when component unmounts
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };

        });   

    return (
        <>
            <div className = "top-row">
                <div className = "profile-icon" onClick = {toggleDropDown}>
                    <img ref = {profileIconRef} src = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt = "profile" />
                </div>
                {isDropDownVisible && (
                        <div ref = {dropDownRef} className = "dropdown-content">
                            <p>{userName}</p>
                            <p>{Email}</p>  
                            <Link to="/login" onClick = {handleLogOut}>Logout</Link>
                        </div>
                    )}
                    <FontAwesomeIcon icon = {faAnglesLeft} className = "back-icon" onClick = {backToDashboard} />
            </div>
        
            <div className = "workout-section">
                <h2 className = "workout-title">My Workouts<img src = {require('./images/workout.png')} alt = "icon" className = "workout-images"></img></h2>
                <div className = "workout-container">
                    <button className = "add-workout-button" onClick = {() => navigate('/addworkout')}>
                        <FontAwesomeIcon icon = {faPlus} className = "add-workout-icon"/>
                    </button>
                    {workout.map((workout, index) => (
                        <div key={index} className="workout-card" onClick={() => handleMuscleClick(workout.muscle)}>
                         <FontAwesomeIcon icon = {faDumbbell}/>
                        <p>{workout.muscle}</p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}