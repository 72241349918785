function Validation(values) {
    let errors = {}

    //email validation regex
    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    //checks if email is empty
    if (values.email === "") {
        errors.email = "Email should not be empty";
    }
    
    //checks if email is valid
    else if (!email_pattern.test(values.email)) {
        errors.email = "Invalid email format"
    }

    //no errors in email
    else {
        errors.email = "";
    }

    //check if password is empty
    if (values.password === "") {
        errors.password = "Password should not be empty";
    }

    //no error in password
    else {
        errors.password = "";
    }

    return errors;

}

export default Validation;
