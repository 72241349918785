import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate} from 'react-router-dom';
import './todolist.css';
import './images/icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Todolist = () => {
    const effectRan = useRef(false);
    const API_URL = process.env.REACT_APP_API_URL;

    const [tasks, setTasks] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const loggedIn = window.localStorage.getItem("isLoggedIn");
    const userName = window.localStorage.getItem('userName');
    const Email = window.localStorage.getItem('Email');
    const userID = window.localStorage.getItem('userID');
    const [savedTasks, setSavedTasks] = useState([]);
    const navigate = useNavigate();

    const [isDropDownVisible, setIsDropDownVisible] = useState(false);
    const dropDownRef = useRef(null);
    const profileIconRef = useRef(null);

    //toggles drop down of profile icon
    function toggleDropDown() {
        setIsDropDownVisible(!isDropDownVisible);
    }

    //clears local storage on log out
    function handleLogOut() {
        window.localStorage.clear();
    }
    
    //check if user is logged in
    let isLoggedIn;
    if (loggedIn === "true") {
        isLoggedIn = true;
    }

    else if (loggedIn === "false") {
        isLoggedIn = false;
    }

    //handles users input for new task
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    }

    //adds task to to-do-list
    const handleAddTask = (event) => {
        event.preventDefault(); // Prevent the default form submission
        if (inputValue.trim() !== '') {
            const taskDuplicate = tasks.filter(task => task.text.toLowerCase() === inputValue.toLowerCase());
            if (!taskDuplicate.length > 0) {
                setTasks(prevTasks => [...prevTasks, { userID: userID, text: inputValue, isChecked: false }]); // Add the new task to the state
            }
            else {
                console.log("This task is a duplicate");
                toast.error('This task is a duplicate', {
                    position: "top-center",
                    autoClose: 1000,
                    closeOnClick: true,
                    hideProgressBar: true,
                });
            }
            setInputValue(''); // Clear the input field
        }
    };

    //removes task to to-do-list
    const handleRemoveTask = (index) => {
        setTasks(prevTasks => prevTasks.filter((_, i) => i !== index));
    };

    //toggles task completion status
    const toggleTask = (index) => {
        setTasks(prevTasks => prevTasks.map((task, i) => {
            if (i === index) {
                return { ...task, isChecked: !task.isChecked };
            }
            return task;
        }));
    };

    //adds task if Enter key is pressed instead of Add button
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          handleAddTask(e);
        }
      };

    //Navigates to dashboard page
    const backToDashboard = (e) => {
        e.preventDefault();
        navigate('/dashboard');
    }

    const saveTasks = async () => {
        try {
        // Filter out tasks that are already saved

        const tasksToSave = tasks.filter(task => !savedTasks.some(savedTask => savedTask.text === task.text 
            && savedTask.userID === task.userID));
        const tasksToUpdate = tasks.filter(task => savedTasks.some(savedTask => savedTask.text === task.text && savedTask.isChecked !== task.isChecked));
        const tasksToDelete = savedTasks.filter(savedTask => !tasks.some(task => task.text === savedTask.text && task.userID === savedTask.userID));

        for (const task of tasksToDelete) {
            await deleteTask(task.text, task.userID); // Assuming deleteTask is a function that sends a DELETE request
        }

        for (const task of tasksToUpdate) {
            await updateTask(task.text, task.isChecked, task.userID); // Assuming updateTask is a function that sends a PUT request
        }

        if (tasksToSave.length > 0) {
            console.log(tasksToSave);
            const response = await axios.post(`${API_URL}/tasks`, { tasks: tasksToSave });
            if (response.status === 201) {
                console.log('Tasks saved successfully');
                // Update the savedTasks state with the new tasks
                setSavedTasks(prevSavedTasks => [...prevSavedTasks, ...tasksToSave]);
            } else {
                console.error('Failed to save tasks');
                // Handle error, e.g., show an error message to the user
            }
        } else {
            console.log('No new tasks to save');
        }
        } catch (error) {
        console.error('Error saving tasks:', error);
        // Handle error, e.g., show an error message to the user
        }
        toast.success('Tasks saved successfully', {
            position: "top-center",
            autoClose: 1000,
            closeOnClick: true,
            hideProgressBar: true,
        });
    }

    const deleteTask = async (taskText, userID) => {
        try {
            const response = await axios.delete(`${API_URL}/tasks/${userID}/${taskText}`);
            if (response.status === 200) {
                console.log('Task deleted successfully');
                // Update the savedTasks state by removing the deleted task
                setSavedTasks(prevSavedTasks => prevSavedTasks.filter(task => task.text !== taskText));
            } else {
                console.error('Failed to delete task');
                // Handle error, e.g., show an error message to the user
            }
        } catch (error) {
            console.error('Error deleting task:', error);
            // Handle error, e.g., show an error message to the user
        }
    }

    const updateTask = async (taskText, isChecked, userID) => {
        try {
        const response = await axios.put(`${API_URL}/tasks/${userID}/${taskText}`, { isChecked });
        if (response.status === 200) {
            console.log('Task updated successfully');
        } else {
            console.error('Failed to update task');
            // Handle error, e.g., show an error message to the user
        }
        } catch (error) {
        console.error('Error updating task:', error);
        // Handle error, e.g., show an error message to the user
        }
    };

    const resetTasks = () => {
        setTasks([]);
    }
  

    useEffect(() => {
        document.title = "TimeCoach | Todolist";
        if (!isLoggedIn) {
            navigate('/login');
        }

        //handle clicks outside of profile icon
        const handleClickOutside= (event) => {
            //checks if click is outside of profile icon
            if (dropDownRef.current && !dropDownRef.current.contains(event.target) && event.target !== profileIconRef.current) {
                //sets dropdown visibility to false
                setIsDropDownVisible(false);
            }
        }

        if (effectRan.current === false) {

            const fetchTasks = async () => {
                try {
                const response = await axios.get(`${API_URL}/tasks/${userID}`);
                console.log(userID);
                setTasks(prevTasks => {
                    // If response.data is an array of tasks
                    if (Array.isArray(response.data)) {
                        const newTasks = response.data.map(task => ({
                          userID: task.userID,
                          text: task.taskDescription,
                          isChecked: task.completed
                        }));
                
                        // Update savedTasks with the same data
                        setSavedTasks(newTasks);
                
                        return [
                          ...prevTasks,
                          ...newTasks
                        ];
                    } else {
                        // If response.data is a single task object
                        const newTask = {
                          userID: response.data.userID,
                          text: response.data.taskDescription,
                          isChecked: response.data.completed
                        };
                
                        // Update savedTasks with the single task
                        setSavedTasks(prevSavedTasks => [...prevSavedTasks, newTask]);
                
                        return [
                          ...prevTasks,
                          newTask
                        ];
                      }
                    });
                  } catch (error) {
                    console.error('Error fetching tasks:', error);
                  }
            };
            fetchTasks();
            }
            effectRan.current = true;

         // Add event listener when component mounts
         document.addEventListener('mousedown', handleClickOutside);

         // Cleanup by removing event listener when component unmounts
         return () => {
             document.removeEventListener('mousedown', handleClickOutside);
         };

    });

    return (
       <>
            <div className = "top-row">
                    <div className = "profile-icon" onClick = {toggleDropDown}>
                        <img ref = {profileIconRef} src = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt = "profile" />
                    </div>
                    {isDropDownVisible && (
                            <div ref = {dropDownRef} className = "dropdown-content">
                                <p>{userName}</p>
                                <p>{Email}</p>  
                                <Link to="/login" onClick = {handleLogOut}>Logout</Link>
                            </div>
                        )}
                        <FontAwesomeIcon icon = {faAnglesLeft} className = "back-icon" onClick = {backToDashboard} />
            </div>
            <div className = "container">
                <h2 className = "container-title">To Do List <img src ={require('./images/icon.png')} alt = "icon" className = "todolist-images" /></h2>
                <div className = "row">
                    <input type ="text" id = "input-box" placeholder = "Add a new task" className = "todolist-input" value={inputValue} onChange={handleInputChange} onKeyDown = {handleKeyDown}/>
                    <button onClick = {handleAddTask}>Add</button>
                </div>
                <ul id = "list-container" className = "todolist-container">
                    {tasks.map((task, index) => (
                            <li className={task.isChecked ? 'checked' : ''} key={index} onClick={() => toggleTask(index)}>{task.text}
                            <span onClick={(e) => {e.stopPropagation(); handleRemoveTask(index)}}>x</span></li>
                    ))}
                </ul>
                <div className = "buttons-container">
                    <button className = "save-button" onClick = {saveTasks}>
                        <FontAwesomeIcon icon = {faSave} /> Save</button>
                    <button className = "reset-button" onClick = {resetTasks}>Reset</button>
                </div>
            </div>
            <ToastContainer theme = "dark"/>
        </> 
    );

};