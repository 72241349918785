import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import './addworkout.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';//toggles drop down of profile icon

export const Addworkout = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const loggedIn = window.localStorage.getItem("isLoggedIn");
    const userName = window.localStorage.getItem('userName');
    const Email = window.localStorage.getItem('Email');
    const UserID = window.localStorage.getItem('userID');
    const [muscle, setMuscle] = useState('');
    const [exercises, setExercises] = useState([]);
    const [savedExercises, setSavedExercises] = useState([]);
    const [isInputDisabled, setIsInputDisabled] = useState(false);

    const [isDropDownVisible, setIsDropDownVisible] = useState(false);
    const dropDownRef = useRef(null);
    const profileIconRef = useRef(null);

    const navigate = useNavigate();

    //handles input change from exercise fields
    const handleInputChange = (index, field, value) => {
        const updatedExercises = [...exercises];
        updatedExercises[index][field] = value;
        setExercises(updatedExercises);
    };

    const handleMuscleChange = (value) => {
        const muscleGroup = value;
        setMuscle(muscleGroup);
    }

    //sets exercise
    const addExercise = () => {
        if (exercises.length > 0) {
            const lastExercise = exercises[exercises.length - 1];
            const exerciseDuplicate = exercises.filter((exercise, index) => 
                index !== exercises.length - 1 && exercise.name.toLowerCase() === lastExercise.name.toLowerCase()
            );

            if (lastExercise.name.trim() !== '' && 
                !isNaN(lastExercise.sets) && lastExercise.sets.trim() !== '' && 
                !isNaN(lastExercise.reps) && lastExercise.reps.trim() !== '' && exerciseDuplicate.length === 0) {
                setExercises([...exercises, { userid: UserID, name: '', sets: '', reps: '', notes: '', muscles: muscle, nameSaved: false }]);
            }

            else if (exerciseDuplicate.length > 0) {
                toast.error('Exercise already exists in list', {
                    position: "top-center",
                    autoClose: 1000,
                    closeOnClick: true,
                    hideProgressBar: true,
                });
            }

            else {
                toast.error('Please fill in all exercise fields (Notes are optional)', {
                    position: "top-center",
                    autoClose: 1000,
                    closeOnClick: true,
                    hideProgressBar: true,
            });
            }
        }

        else if (muscle.length === 0) {
            toast.error('Please enter in a muscle group', {
                position: "top-center",
                autoClose: 1000,
                closeOnClick: true,
                hideProgressBar: true,
        });
        }

        else {
            setExercises([...exercises, { userid: UserID, name: '', sets: '', reps: '', notes: '', muscles: muscle, nameSaved: false }]);
        }
    };

    //toggles drop down of profile icon
    function toggleDropDown() {
       setIsDropDownVisible(!isDropDownVisible);
   }

   //clears local storage on log out
   function handleLogOut() {
       window.localStorage.clear();
   }
   
   //check if user is logged in
   let isLoggedIn;
   if (loggedIn === "true") {
       isLoggedIn = true;
   }

   else if (loggedIn === "false") {
       isLoggedIn = false;
   }

   //navigate to workout page
   const backToDashboard = (e) => {
       e.preventDefault();
       navigate('/workout');
   }

   const saveExercises = async () => {
        try {

            const invalidExercises = exercises.filter(exercise => 
                exercise.name.trim() === '' || 
                isNaN(exercise.sets) || exercise.sets.trim() === '' || 
                isNaN(exercise.reps) || exercise.reps.trim() === ''
            );
    
            if (invalidExercises.length > 0) {
                toast.error('Please fill in all exercise fields (Notes are optional)', {
                    position: "top-center",
                    autoClose: 1000,
                    closeOnClick: true,
                    hideProgressBar: true,
                });
                return;
            }
    
            const duplicateExercises = exercises.filter((exercise, index) => 
                exercises.some((e, i) => i !== index && e.name.toLowerCase() === exercise.name.toLowerCase())
            );
    
            if (duplicateExercises.length > 0) {
                toast.error('Exercise already exists in list', {
                    position: "top-center",
                    autoClose: 1000,
                    closeOnClick: true,
                    hideProgressBar: true,
                });
                return;
            }

            const exercisesToSave = exercises.filter(exercise => !savedExercises.some(savedExercises => savedExercises.name === exercise.name && 
                savedExercises.userid === exercise.userid));
                
                const exercisesToUpdate = exercises.filter(exercise => 
                    savedExercises.some(savedExercise => 
                        savedExercise.name === exercise.name &&
                        (savedExercise.sets !== exercise.sets || 
                         savedExercise.reps !== exercise.reps || 
                         savedExercise.notes !== exercise.notes)
                    )
                );     
            const exercisesToDelete = savedExercises.filter(savedExercise => !exercises.some(exercise => exercise.name === savedExercise.name 
                && exercise.userid === savedExercise.userid));

                console.log('Exercises to Save:', exercisesToSave);
                console.log('Exercises to Update:', exercisesToUpdate);
                console.log('Exercises to Delete:', exercisesToDelete);
                console.log('savedExercises:',savedExercises);
                console.log('exercises:',exercises);


            for (const exercise of exercisesToDelete) {
                await deleteExercise(exercise.name, exercise.userid);
            }

            for (const exercise of exercisesToUpdate) {
                await updateExercise(exercise.name, exercise.userid, exercise.sets, exercise.reps, exercise.notes, exercise.muscles);
            }

            if (exercisesToSave.length > 0) {
                console.log(exercisesToSave);
                const response = await axios.post(`${API_URL}/workout`, { workout: exercisesToSave });
                if (response.status === 201) {
                    console.log('Exercises saved successfully');
                    // Update the savedTasks state with the new exercises
                    setSavedExercises(prevSavedExercises => [...prevSavedExercises, ...exercisesToSave]);

                    setExercises(prevExercises => 
                        prevExercises.map(exercise => 
                            exercisesToSave.some(savedExercise => savedExercise.name === exercise.name && savedExercise.userid === exercise.userid)
                                ? { ...exercise, nameSaved: true }
                                : exercise
                        )
                    );
                    setIsInputDisabled(true);
                } else {
                    console.error('Failed to save exercises');
                    // Handle error, e.g., show an error message to the user
                }
            } else {
                console.log('No new exercises to save');
            }
        } catch (error) {
            console.error('Error saving exercises:', error);
            // Handle error, e.g., show an error message to the user
        }
        toast.success('Workout saved successfully', {
            position: "top-center",
            autoClose: 1000,
            closeOnClick: true,
            hideProgressBar: true,
        });
    }  

    const updateExercise = async(exerciseName, userID, sets, reps, notes, muscles) => {
        try {
            const url = `${API_URL}/workout/${exerciseName}/${userID}`;
            const data = {
                sets: sets,
                reps: reps,
                notes: notes,
                muscles: muscles
            };
    
            const response = await axios.put(url, data);
    
            if (response.status === 200) {
                console.log('Exercise updated successfully');
                console.log(response.data);
                // Update the savedExercises state
                setSavedExercises(prevSavedExercises => 
                    prevSavedExercises.map(exercise => 
                        exercise.name === exerciseName ? { ...exercise, ...data } : exercise
                    )
                );
            } else {
                console.error('Failed to update exercise');
            }
        } catch (error) {
            console.error('Error updating exercise:', error);
        }
    };
    
    const deleteExercise = async (exerciseName, userID) => {
        try {
            const response = await axios.delete(`${API_URL}/workout/${userID}/${exerciseName}`);
            if (response.status === 200) {
                console.log('Exercise deleted successfully');
                // Update the savedExercises state by removing the deleted exercise
                setSavedExercises(prevSavedExercises => prevSavedExercises.filter(exercise => exercise.name !== exerciseName));
            } else {
                console.error('Failed to delete exercise');
                // Handle error, e.g., show an error message to the user
            }
        } catch (error) {
            console.error('Error deleting exercise:', error);
            // Handle error, e.g., show an error message to the user
        }
    }

    const handleRemoveExercise = (index) => {
        setExercises(exercises.filter((_, i) => i !== index));
    }

   useEffect(() => {
    document.title = "TimeCoach | Add Workout";
    if (!isLoggedIn) {
        navigate('/login');
    }

    //handle clicks outside of profile icon
    const handleClickOutside= (event) => {
        //checks if click is outside of profile icon
        if (dropDownRef.current && !dropDownRef.current.contains(event.target) && event.target !== profileIconRef.current) {
            //sets dropdown visibility to false
            setIsDropDownVisible(false);
        }
    }
        // Add event listener when component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup by removing event listener when component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
});

    return (
    <>
        <div className = "top-row">
            <div className = "profile-icon" onClick = {toggleDropDown}>
                <img ref = {profileIconRef} src = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt = "profile" />
            </div>
            {isDropDownVisible && (
                    <div ref = {dropDownRef} className = "dropdown-content">
                        <p>{userName}</p>
                        <p>{Email}</p>  
                        <Link to="/login" onClick = {handleLogOut}>Logout</Link>
                    </div>
                )}
                <FontAwesomeIcon icon = {faAnglesLeft} className = "back-icon" onClick = {backToDashboard} />
            </div>
            <div className = "add-workout-container">
                <h2 className = "add-workout-title">Add Workout<img src = {require('../images/workout.png')} alt = "icon" className = "workout-images"></img></h2>
                <input type ="text" id = "input-box" placeholder = "Muscle Group" className = "workout-input" disabled = {isInputDisabled} onChange={(e) => handleMuscleChange(e.target.value)}></input>
                <button className = "add-workout-button-2" onClick = {addExercise}>
                    <FontAwesomeIcon icon = {faPlus} className = "add-workout-icon"/>
                </button>
                <hr className = "separator"></hr>
                <div className="exercise-details">
                    <div className="exercise-label">Exercise Name</div>
                    <div className="exercise-label">Sets</div>
                    <div className="exercise-label">Reps</div>
                    <div className="exercise-label">Notes</div>
                </div>
                {exercises.map((exercise, index) => (
                    <div key={index} className="exercise-inputs">
                    <input
                        type="text"
                        value={exercise.name}
                        onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                        placeholder="Exercise Name"
                        className="exercise-input-1"
                        disabled={exercise.nameSaved}
                    />
                    <input
                        type="text"
                        value={exercise.sets}
                        onChange={(e) => handleInputChange(index, 'sets', e.target.value)}
                        placeholder="Sets"
                        className="exercise-input-2"
                    />
                    <input
                        type="text"
                        value={exercise.reps}
                        onChange={(e) => handleInputChange(index, 'reps', e.target.value)}
                        placeholder="Reps"
                        className="exercise-input-2"
                    />
                    <input
                        type="text"
                        value={exercise.notes}
                        onChange={(e) => handleInputChange(index, 'notes', e.target.value)}
                        placeholder="Notes"
                        className="exercise-input-1"
                    />
                    <span className = "remove-exercise-icon"onClick={(e) => {e.stopPropagation(); handleRemoveExercise(index)}}>x</span>
                    </div>
                ))}
                <button className = "save-workout-button" onClick = {saveExercises}>Save</button>
            </div>
            <ToastContainer theme = "dark"/>
        </> 
    )
}