// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  /* background-image: linear-gradient(79deg, #7439db, #C66FBC 48%, #F79940); */
  background-color: #F2F5F5;
  overflow: visible;
}

.auth-form-container, .login-form, .register-form {
  display: flex;
  flex-direction: column;

}

@media screen and (min-width: 600px) {
  .auth-form-container {
    padding: 5rem;
    border: 1px solid white;
    background-color: white;
    border-radius: 10px;
    margin: 0.5rem;
    border: 1px solid black;
  }
}

label {
  text-align: left;
  padding: 0.25rem 0;
}

input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
  background-color: rgb(219, 219, 219);
}

button {
  border: none;
  background-color: rgb(32, 193, 214);
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.link-btn {
  background: none;
  color: black;
  text-decoration: underline;
  font-size: 0.7rem;
  margin-top: 10px;
}

.link-btn:hover {
  background-color: none;
  color: rgb(3, 201, 201);
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,uBAAuB;EACvB,6EAA6E;EAC7E,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;;AAExB;;AAEA;EACE;IACE,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,uBAAuB;EACzB;AACF;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,oCAAoC;AACtC;;AAEA;EACE,YAAY;EACZ,mCAAmC;EACnC,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,0BAA0B;EAC1B,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":[".App {\n  text-align: center;\n  display: flex;\n  min-height: 100vh;\n  align-items: center;\n  justify-content: center;\n  /* background-image: linear-gradient(79deg, #7439db, #C66FBC 48%, #F79940); */\n  background-color: #F2F5F5;\n  overflow: visible;\n}\n\n.auth-form-container, .login-form, .register-form {\n  display: flex;\n  flex-direction: column;\n\n}\n\n@media screen and (min-width: 600px) {\n  .auth-form-container {\n    padding: 5rem;\n    border: 1px solid white;\n    background-color: white;\n    border-radius: 10px;\n    margin: 0.5rem;\n    border: 1px solid black;\n  }\n}\n\nlabel {\n  text-align: left;\n  padding: 0.25rem 0;\n}\n\ninput {\n  margin: 0.5rem 0;\n  padding: 1rem;\n  border: none;\n  border-radius: 10px;\n  background-color: rgb(219, 219, 219);\n}\n\nbutton {\n  border: none;\n  background-color: rgb(32, 193, 214);\n  padding: 20px;\n  border-radius: 10px;\n  cursor: pointer;\n}\n\n.link-btn {\n  background: none;\n  color: black;\n  text-decoration: underline;\n  font-size: 0.7rem;\n  margin-top: 10px;\n}\n\n.link-btn:hover {\n  background-color: none;\n  color: rgb(3, 201, 201);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
