import React, { useState, useEffect, useRef } from "react";
import Validation from './LoginValidation';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './authentication.css';

export const Login = () => {
    const effectRan = useRef(false); //checks if effect has been ran at least once
    const API_URL = process.env.REACT_APP_API_URL;

    //initializing state variables
    const [values, setValues] = useState({
        email: '',
        password: ''
    });
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    //handles input from textboxes
    const handleInput = (e) => {
        setValues(prev => ({...prev, [e.target.name] : [e.target.value]}));
    }

    //handles form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = Validation(values);
        setErrors(validationErrors);
        effectRan.current = false;
    }

    //checks if login details are valid and redirects to home page if true
    useEffect(() => {
        document.title = "Time Coach | Login"; 
        if (effectRan.current === false) { //checks if effect has been ran at least once
            effectRan.current = true;   //set effectRan to true to prevent multiple requests
            if (errors.email === "" && errors.password === "") { //checks if there are no errors in email and password
                axios.post(`${API_URL}/login`, values)  //checks if login details are valid
                    .then(res => {
                        if (res.data.Login === true) { //if login details are valid, redirect to dashboard page
                            const userName = res.data.data[0].Username;
                            const Email = res.data.data[0].Email;
                            const userID = res.data.data[0].UserID;
                            navigate('/dashboard'); 
                            window.localStorage.setItem('isLoggedIn', true);
                            window.localStorage.setItem('userName', userName);
                            window.localStorage.setItem('Email', Email);
                            window.localStorage.setItem('userID', userID);
                        } else { //if login details are invalid, show error message
                            toast.error("Invalid email or password", {
                                position: "top-center",
                                autoClose: 3000,
                                closeOnClick: true,
                                hideProgressBar: true,
                            });
                        }
                    })
                    .catch(err => console.log(err));     
            }
        }
    }, [errors, values, navigate, API_URL]);

    //Login Component
    return (
        <div className = "auth-form-container">
            <h2>Login</h2>
            <form className = "login-form" onSubmit = {handleSubmit}>
                <label htmlFor = "email">Email</label>
                <input type = "email" onChange = {handleInput} placeholder = "youreemail@gmail.com" id = "email" name = "email"/>
                {errors.email && <span className = "text-danger">{errors.email}</span>}
                <label htmlFor = "password">Password</label>
                <input type = "password" onChange = {handleInput} placeholder = "*********" id = "password" name = "password"/>
                {errors.password && <span className = "text-danger">{errors.password}</span>}
                <button>Log In</button>
            </form>
            <Link to ='/register' className = "link-btn">Don't have an account? Register here.</Link>
            <ToastContainer theme = "dark"/>
        </div> 
    )
}