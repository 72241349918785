import React, { useState, useEffect, useRef } from "react";
import Validation from "./SignupValidation";
import axios from 'axios';
import './authentication.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';

export const Register = (props) => {
    const effectRan = useRef(false);    //checks if the effect has been run at least once
    const API_URL = process.env.REACT_APP_API_URL;

    //initializing state
    const [values, setValues] = useState({
        name: '',
        email: '',
        password: ''
    }); 

    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    //handles input from textboxes
    const handleInput = (e) => {
        setValues(prev => ({...prev, [e.target.name] : [e.target.value]}));
    }

    //handles form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors(Validation(values));
        effectRan.current = false;
    }

    //redirects to login page if user successfully registers
    useEffect(() => {
        document.title = "Time Coach | Register";
        if (effectRan.current === false) { //checks if the effect has been run at least once
            effectRan.current = true; //sets the effect as run
            if (errors.name === "" && errors.email === "" && errors.password === "") { //checks if there are no errors
                axios.post(`${API_URL}/register`, values)  //checks if the email already exists in the database
                .then(response=> {
                    if (response.data.exists) { //email already exists 
                        toast.error("Email already exists. Please login or try again with a different email.", {
                            position: "top-center",
                            autoClose: 3000,
                            closeOnClick: true,
                            hideProgressBar: true,
                        });
                    }
    
                    else {
                        axios.post(`${API_URL}/users`, values) //add user to database
                        .then(res => {
                            console.log("Post request successful:", res);
                            console.log("Data sent:", values);
                            navigate('/login'); //navigate to login page
                        })
                        .catch(err => console.log(err));

                    }
                })
            }
        }
        
    },[errors, navigate, values, API_URL]);

    //Register Component
    return(
        <div className = "auth-form-container">
            <h2>Register</h2>
            <form className = "register-form" onSubmit = {handleSubmit}>
                <label htmlFor = "name">Full Name</label>
                <input onChange = {handleInput} name = "name" id = "name" placeholder = "Full Name" ></input>
                {errors.name && <span className = "text-danger">{errors.name}</span>}
                <label htmlFor = "email">Email</label>
                <input onChange = {handleInput} type = "email" placeholder = "youreemail@gmail.com" id = "email" name = "email"/>
                {errors.email && <span className = "text-danger">{errors.email}</span>}
                <label htmlFor = "password">Password</label>
                <input onChange = {handleInput} type = "password" placeholder = "*********" id = "password" name = "password"/>
                {errors.password && <span className = "text-danger">{errors.password}</span>}
                <button>Sign Up</button>
            </form>
            <Link to = '/login' className = "link-btn">Already have an account? Login here.</Link>
            <ToastContainer theme = "dark"/>
        </div>
    )
}