// import React, { useState, useEffect, useRef } from "react"
// import logo from './logo.svg';
import './App.css';
import { Login } from './components/authentication/Login';
import { Register } from './components/authentication/Register';
import { Dashboard } from './components/dashboard/Dashboard';
import { Todolist } from './components/todolist/Todolist';
import { Calendar } from './components/calendar/Calendar';
import { Workout } from './components/workout/Workout';
import { Addworkout } from './components/workout/addworkout/Addworkout';
import { WorkoutDetails } from './components/workout/workoutdetails/WorkoutDetails';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';

function App() {
  const loggedIn = window.localStorage.getItem("isLoggedIn");
  let isLoggedIn;
  if (loggedIn === "true") {
    isLoggedIn = true;
  }

  else if (loggedIn === "false") {
    isLoggedIn = false;
  }

  //Main App Component
  return (
    <div className="App">
      {
        <BrowserRouter>
          <Routes>
            <Route element={isLoggedIn ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} path='/' />
              <Route path = '/login' element = {<Login/>}></Route>
              <Route path = '/register' element = {<Register/>}></Route>
              <Route path='/dashboard' element={<Dashboard/>}></Route>
              <Route path = '/todolist' element = {<Todolist/>}></Route>
              <Route path = '/calendar' element = {<Calendar/>}></Route>
              <Route path = '/workout' element = {<Workout/>}></Route>
              <Route path='/addworkout' element={<Addworkout />}></Route>
              <Route path='/workout/:muscles' element={<WorkoutDetails />} />
          </Routes>
        </BrowserRouter>
      }
    </div>
  );
}

export default App;
