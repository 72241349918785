import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate} from 'react-router-dom';
import './dashboard.css';
import './images/calendar.jpg';
import './images/checklist.jpg';
import './images/workout.jpg';

export const Dashboard = () => {
    const navigate = useNavigate();
    const loggedIn = window.localStorage.getItem("isLoggedIn");
    const userName = window.localStorage.getItem('userName');
    const Email = window.localStorage.getItem('Email');
    const [isDropDownVisible, setIsDropDownVisible] = useState(false);
    const dropDownRef = useRef(null);
    const profileIconRef = useRef(null);

    //check if user is logged in
    let isLoggedIn;
    if (loggedIn === "true") {
        isLoggedIn = true;
    }

    else if (loggedIn === "false") {
        isLoggedIn = false;
    }
    
    //toggles drop down of profile icon
    function toggleDropDown() {
        setIsDropDownVisible(!isDropDownVisible);
    }

    //clears local storage on log out
    function handleLogOut() {
        window.localStorage.clear();
    }

    useEffect(() => {
        document.title = "TimeCoach | Dashboard";
        if (!isLoggedIn) {
            navigate('/login');
        }

        //handle clicks outside of profile icon
        const handleClickOutside= (event) => {
            //checks if click is outside of profile icon
            if (dropDownRef.current && !dropDownRef.current.contains(event.target) && event.target !== profileIconRef.current) {
                //sets dropdown visibility to false
                setIsDropDownVisible(false);
            }
        }

         // Add event listener when component mounts
         document.addEventListener('mousedown', handleClickOutside);

         // Cleanup by removing event listener when component unmounts
         return () => {
             document.removeEventListener('mousedown', handleClickOutside);
         };
    });



    return (
       <>
            <div className = "top-row">
                <div className = "profile-icon" onClick = {toggleDropDown}>
                    <img ref = {profileIconRef} src = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt = "profile" />
                </div>
                {isDropDownVisible && (
                        <div ref = {dropDownRef} className = "dropdown-content">
                            <p>{userName}</p>
                            <p>{Email}</p>  
                            <Link to="/login" onClick = {handleLogOut}>Logout</Link>
                        </div>
                    )}
                <div className = "welcome-message">Welcome {userName}!</div>
            </div>
            <div className = "dashboard">
                <Link to = "/calendar" className = "rectangle">
                    Calendar
                    <img src = {require('./images/calendar.jpg')} alt = "calendar" className = "images" />
                </Link>
                <Link to = "/todolist" className = "rectangle">
                    To-do list
                    <img src = {require('./images/checklist.jpg')} alt = "checklist" className = "images" />
                </Link>
                <Link to = "/workout" className = "rectangle">
                    Workout
                    <img src = {require('./images/workout.jpg')} alt = "workout" className = "images" />
                </Link>
            </div>
        </> 
    )

};