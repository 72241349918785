import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import "./workoutDetails.css"
import axios from "axios";
import {ToastContainer, toast} from 'react-toastify';
import _ from 'lodash';

export const WorkoutDetails = () => {
    const effectRan = useRef(false);
    const API_URL = process.env.REACT_APP_API_URL;
    const loggedIn = window.localStorage.getItem("isLoggedIn");
    const userName = window.localStorage.getItem('userName');
    const Email = window.localStorage.getItem('Email');
    const UserID = window.localStorage.getItem('userID');
    const [exercises, setExercises] = useState([]);
    const [savedExercises, setSavedExercises] = useState([]);

    const [isDropDownVisible, setIsDropDownVisible] = useState(false);
    const dropDownRef = useRef(null);
    const profileIconRef = useRef(null);

    const navigate = useNavigate();
    const { muscles } = useParams();

    //handles input change from exercise fields
    const handleInputChange = (index, field, value) => {
        const updatedExercises = _.cloneDeep(exercises);
        updatedExercises[index][field] = value;
        setExercises(updatedExercises);
        console.log("exercises", exercises);
        console.log("savedExercises", savedExercises);
    };

    //removes Exercise
    const handleRemoveExercise = (index) => {
        setExercises(exercises.filter((_, i) => i !== index));
    }

    //sets exercise
    const addExercise = () => {
        if (exercises.length > 0) {
            const lastExercise = exercises[exercises.length - 1];
            const exerciseDuplicate = exercises.filter((exercise, index) => 
                index !== exercises.length - 1 && exercise.name.toLowerCase() === lastExercise.name.toLowerCase()
            );

            if (lastExercise.name.trim() !== '' && 
                !isNaN(lastExercise.sets) && lastExercise.sets.toString().trim() !== '' && 
                !isNaN(lastExercise.reps) && lastExercise.reps.toString().trim() !== '' && exerciseDuplicate.length === 0) {
                setExercises([...exercises, { userid: UserID, name: '', sets: '', reps: '', notes: '', muscles: muscles, nameSaved: false }]);
            }

            else if (exerciseDuplicate.length > 0) {
                toast.error('Exercise already exists in list', {
                    position: "top-center",
                    autoClose: 1000,
                    closeOnClick: true,
                    hideProgressBar: true,
                });
            }

            else {
                toast.error('Please fill in all exercise fields (Notes are optional)', {
                    position: "top-center",
                    autoClose: 1000,
                    closeOnClick: true,
                    hideProgressBar: true,
            });
            }
        }

        else if (muscles.length === 0) {
            toast.error('Please enter in a muscle group', {
                position: "top-center",
                autoClose: 1000,
                closeOnClick: true,
                hideProgressBar: true,
        });
        }

        else {
            setExercises([...exercises, { userid: UserID, name: '', sets: '', reps: '', notes: '', muscles: muscles, nameSaved: false }]);
        }
    };

    //toggles drop down of profile icon
    function toggleDropDown() {
        setIsDropDownVisible(!isDropDownVisible);
    }
 
    //clears local storage on log out
    function handleLogOut() {
        window.localStorage.clear();
    }
    
    //check if user is logged in
    let isLoggedIn;
    if (loggedIn === "true") {
        isLoggedIn = true;
    }
 
    else if (loggedIn === "false") {
        isLoggedIn = false;
    }
 
    //navigate to workout page
    const backToDashboard = (e) => {
        e.preventDefault();
        navigate('/workout');
    }

    const saveExercises = async () => {
        console.log("saveExercises");
        try {

            const invalidExercises = exercises.filter(exercise => 
                exercise.name.trim() === '' || 
                isNaN(exercise.sets) || exercise.sets.toString().trim() === '' || 
                isNaN(exercise.reps) || exercise.reps.toString().trim() === ''
            );
    
            if (invalidExercises.length > 0) {
                toast.error('Please fill in all exercise fields (Notes are optional)', {
                    position: "top-center",
                    autoClose: 1000,
                    closeOnClick: true,
                    hideProgressBar: true,
                });
                return;
            }
    
            const duplicateExercises = exercises.filter((exercise, index) => 
                exercises.some((e, i) => i !== index && e.name.toLowerCase() === exercise.name.toLowerCase())
            );
    
            if (duplicateExercises.length > 0) {
                toast.error('Exercise already exists in list', {
                    position: "top-center",
                    autoClose: 1000,
                    closeOnClick: true,
                    hideProgressBar: true,
                });
                return;
            }

            const exercisesToSave = exercises.filter(exercise => !savedExercises.some(savedExercises => savedExercises.name === exercise.name && 
                savedExercises.userid === exercise.userid));

                const exercisesToUpdate = exercises.filter(exercise => 
                    savedExercises.some(savedExercise => 
                        savedExercise.name === exercise.name &&
                        (savedExercise.sets !== exercise.sets || 
                         savedExercise.reps !== exercise.reps || 
                         savedExercise.notes !== exercise.notes)
                    )
                );  
            const exercisesToDelete = savedExercises.filter(savedExercise => !exercises.some(exercise => exercise.name === savedExercise.name
                && exercise.userid === savedExercise.userid));

                console.log('Exercises to Save:', exercisesToSave);
                console.log('Exercises to Update:', exercisesToUpdate);
                console.log('Exercises to Delete:', exercisesToDelete);
                console.log('savedExercises:',savedExercises);
                console.log('exercises:',exercises);


            for (const exercise of exercisesToDelete) {
                await deleteExercise(exercise.name);
            }

            for (const exercise of exercisesToUpdate) {
                await updateExercise(exercise.name, exercise.sets, exercise.reps, exercise.notes, exercise.muscles);
            }

            if (exercisesToSave.length > 0) {
                const response = await axios.post(`${API_URL}/workout`, { workout: exercisesToSave });
                if (response.status === 201) {
                    console.log('Exercises saved successfully');
                    // Update the savedTasks state with the new exercises
                    setSavedExercises(prevSavedExercises => [...prevSavedExercises, ...exercisesToSave]);

                    setExercises(prevExercises => 
                        prevExercises.map(exercise => 
                            exercisesToSave.some(savedExercise => savedExercise.name === exercise.name && savedExercise.userid === exercise.userid)
                                ? { ...exercise, nameSaved: true }
                                : exercise
                        )
                    );
                } else {
                    console.error('Failed to save exercises');
                    // Handle error, e.g., show an error message to the user
                }
            } else {
                console.log('No new exercises to save');
            }
        } catch (error) {
            console.error('Error saving exercises:', error);
            // Handle error, e.g., show an error message to the user
        }
        toast.success('Workout saved successfully', {
            position: "top-center",
            autoClose: 1000,
            closeOnClick: true,
            hideProgressBar: true,
        });
    }  

    const updateExercise = async(exerciseName, sets, reps, notes, muscles) => {
        try {
            const url = `${API_URL}/workout/${exerciseName}/${UserID}`;
            const data = {
                sets: sets,
                reps: reps,
                notes: notes,
                muscles: muscles
            };
    
            const response = await axios.put(url, data);
    
            if (response.status === 200) {
                console.log('Exercise updated successfully');
                console.log(response.data);
                // Update the savedExercises state
                setSavedExercises(prevSavedExercises => 
                    prevSavedExercises.map(exercise => 
                        exercise.name === exerciseName ? { ...exercise, ...data } : exercise
                    )
                );
            } else {
                console.error('Failed to update exercise');
            }
        } catch (error) {
            console.error('Error updating exercise:', error);
        }
    };
    
    const deleteExercise = async (exerciseName) => {
        console.log("deleteExercise");
        try {
            const response = await axios.delete(`${API_URL}/workout/${UserID}/${exerciseName}`);
            if (response.status === 200) {
                console.log('Exercise deleted successfully');
                // Update the savedExercises state by removing the deleted exercise
                setSavedExercises(prevSavedExercises => prevSavedExercises.filter(exercise => exercise.name !== exerciseName));
            } else {
                console.error('Failed to delete exercise');
                // Handle error, e.g., show an error message to the user
            }
        } catch (error) {
            console.error('Error deleting exercise:', error);
            // Handle error, e.g., show an error message to the user
        }
    }

    useEffect(() => {
        console.log("useEffect");
        document.title = "TimeCoach | Workout Details";
        if (!isLoggedIn) {
            navigate('/login');
        }
    
        //handle clicks outside of profile icon
        const handleClickOutside= (event) => {
            //checks if click is outside of profile icon
            if (dropDownRef.current && !dropDownRef.current.contains(event.target) && event.target !== profileIconRef.current) {
                //sets dropdown visibility to false
                setIsDropDownVisible(false);
            }
        }

        if (effectRan.current === false) {
            console.log("useEffect (effectRan)");

            const fetchExercises = async () => {
                try {
                const response = await axios.get(`${API_URL}/workout/${muscles}/${UserID}`);
                    // If response.data is an array of tasks
                    if (Array.isArray(response.data)) {
                        const newExercise = response.data.map(exercise => ({
                          userID: exercise.userID,
                          name: exercise.name,
                          sets: exercise.sets,
                          reps: exercise.reps,
                          notes: exercise.notes,
                          muscles: exercise.muscle,
                          nameSaved: exercise.nameSaved,
                        }));

                        // Update savedTasks with the same data
                        console.log("newExercise: ", newExercise);
                        setSavedExercises(newExercise);
                        setExercises(prevExercises => [...prevExercises, ...newExercise]);

                    } else {
                        // If response.data is a single task object
                        const newExercise = {
                          userID: response.data.userID,
                          name: response.data.name,
                          sets: response.data.sets,
                          reps: response.data.reps,
                          notes: response.data.notes,
                          muscles: response.data.muscle,
                          nameSaved: response.data.nameSaved,
                        };
                
                        // Update savedTasks with the single task
                        setSavedExercises(prevSavedExercises => [...prevSavedExercises, newExercise]);
                        setExercises(prevExercises => [...prevExercises, newExercise]);
                      }
                  } catch (error) {
                    console.error('Error fetching tasks:', error);
                  }
            };
            fetchExercises();
            }
        effectRan.current = true;
            // Add event listener when component mounts
            document.addEventListener('mousedown', handleClickOutside);
    
            // Cleanup by removing event listener when component unmounts
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
    });


    return (
        <>
            <div className = "top-row">
            <div className = "profile-icon" onClick = {toggleDropDown}>
                <img ref = {profileIconRef} src = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt = "profile" />
            </div>
            {isDropDownVisible && (
                    <div ref = {dropDownRef} className = "dropdown-content">
                        <p>{userName}</p>
                        <p>{Email}</p>  
                        <Link to="/login" onClick = {handleLogOut}>Logout</Link>
                    </div>
                )}
                <FontAwesomeIcon icon = {faAnglesLeft} className = "back-icon" onClick = {backToDashboard} />
            </div>
            <div className = "add-workout-container">
                <h2 className = "workout-details-title">{muscles}<img src = {require('../images/workout.png')} alt = "icon" className = "workout-images"></img></h2>
                <button className = "add-workout-button-3" onClick = {addExercise}>
                    <FontAwesomeIcon icon = {faPlus} className = "add-workout-icon"/>
                </button>
                <hr className = "separator-2"></hr>
                <div className="exercise-details-2">
                    <div className="exercise-label">Exercise Name</div>
                    <div className="exercise-label">Sets</div>
                    <div className="exercise-label">Reps</div>
                    <div className="exercise-label">Notes</div>
                </div>
                {exercises.map((exercise, index) => (
                    <div key={index} className="exercise-inputs">
                    <input
                        type="text"
                        value={exercise.name}
                        onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                        placeholder="Exercise Name"
                        className="exercise-input-1"
                        disabled={exercise.nameSaved}
                    />
                    <input
                        type="text"
                        value={exercise.sets}
                        onChange={(e) => handleInputChange(index, 'sets', e.target.value)}
                        placeholder="Sets"
                        className="exercise-input-2"
                    />
                    <input
                        type="text"
                        value={exercise.reps}
                        onChange={(e) => handleInputChange(index, 'reps', e.target.value)}
                        placeholder="Reps"
                        className="exercise-input-2"
                    />
                    <input
                        type="text"
                        value={exercise.notes}
                        onChange={(e) => handleInputChange(index, 'notes', e.target.value)}
                        placeholder="Notes"
                        className="exercise-input-1"
                    />
                    <span className = "remove-exercise-icon"onClick={(e) => {e.stopPropagation(); handleRemoveExercise(index)}}>x</span>
                    </div>
                ))}
                <button className = "save-workout-button" onClick = {saveExercises}>Save</button>
            </div>
            <ToastContainer theme = "dark"/>
        </>
    )

}