import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons';
import './calendar.css';  // Import the CSS file
import axios from "axios";

//checks how many days are in a given month and returns the number
const daysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();

//checks what day of the week the first day of a given month is and returns the number (1-7)
const firstDayOfMonth = (year, month) => new Date(year, month, 1).getDay();

// Simple Alert component
const Alert = ({ title, children }) => (
    <div className="alert">
        <p className="alert-title">{title}</p>
        <div>{children}</div>
    </div>
);

export const Calendar = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const effectRan = useRef(false);
    const loggedIn = window.localStorage.getItem("isLoggedIn");
    const userName = window.localStorage.getItem('userName');
    const Email = window.localStorage.getItem('Email');
    const UserID = window.localStorage.getItem('userID');

    const [isDropDownVisible, setIsDropDownVisible] = useState(false);
    const dropDownRef = useRef(null);
    const profileIconRef = useRef(null);

    const navigate = useNavigate();

    // Calendar state
    const [currentDate, setCurrentDate] = useState(new Date());
    const [events, setEvents] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);
    const [newEvent, setNewEvent] = useState('');
    const [showAddEventForm, setShowAddEventForm] = useState(false);

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    function toggleDropDown() {
        setIsDropDownVisible(!isDropDownVisible);
    }

    function handleLogOut() {
        window.localStorage.clear();
    }
    
    let isLoggedIn = loggedIn === "true";

    const backToDashboard = (e) => {
        e.preventDefault();
        navigate('/dashboard');
    }

    const generateCalendarDays = () => {
        // Get the current year and month from the currentDate state
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        
        // Determine the number of days in the current month
        const daysCount = daysInMonth(year, month);
        
        // Determine the day of the week for the first day of the current month
        const firstDay = firstDayOfMonth(year, month);
        
        // Initialize an empty array to hold the days
        const days = [];
    
        // Add null placeholders for the days before the first day of the current month
        for (let i = 0; i < firstDay; i++) {
            days.push(null);
        }
    
        // Add Date objects for each day in the current month
        for (let i = 1; i <= daysCount; i++) {
            days.push(new Date(year, month, i));
        }
    
        // Return the array of days
        return days;
    };

    //checks if month is within the last 6 months or future 6 months
    const isWithinRange = (date) => {
        const now = new Date();
        const sixMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 6, 1);
        const sixMonthsFromNow = new Date(now.getFullYear(), now.getMonth() + 6, 0);
        return date >= sixMonthsAgo && date <= sixMonthsFromNow;
    };

    //handles previous month
    const handlePrevMonth = () => {
        setCurrentDate(prevDate => {
            const newDate = new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1);
            return isWithinRange(newDate) ? newDate : prevDate;
        });
    };

    //handles next month
    const handleNextMonth = () => {
        setCurrentDate(prevDate => {
            const newDate = new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1);
            return isWithinRange(newDate) ? newDate : prevDate;
        });
    };

    const handleDateClick = (date) => {
        if (isWithinRange(date)) {
            setSelectedDate(date);
            setShowAddEventForm(true);
        }
    };

    const handleAddEvent = async () => {
        if (newEvent.trim() !== '' && selectedDate) {
            const dateKey = selectedDate.toISOString().split('T')[0];
            console.log(newEvent.trim());
            
            try {
                // Send the event data to the backend
                const response = await axios.post(`${API_URL}/api/events`, {
                    userID: UserID,
                    date: dateKey,
                    event: newEvent.trim()
                });
                // Update the local state if the save was successful
                setEvents(prev => ({
                    ...prev,
                    [dateKey]: [...(prev[dateKey] || []), newEvent.trim()]
                }));
                setNewEvent('');
                setShowAddEventForm(false);
            } catch (error) {
                console.error('Error saving event:', error);
                // Optionally, show an error message to the user
            }
        }
    };


    //checks if previous month is disabled or not
    const isPrevMonthDisabled = () => {
        const prevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        return !isWithinRange(prevMonth);
    };

    //checks if next month is disabled or not
    const isNextMonthDisabled = () => {
        const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        return !isWithinRange(nextMonth);
    };

    const removeEvent = async (date, eventDescription) => {

        const dateKey = date.toISOString().split('T')[0];

        try {
            // Make an API call to delete the event from the backend
            const response = await axios.delete(`${API_URL}/api/events/${UserID}`, {
                data: {
                    date: dateKey,
                    event: eventDescription
                }
            });
            // Update the local state if the delete was successful
            setEvents(prevEvents => {
                const updatedEvents = { ...prevEvents };
                if (updatedEvents[dateKey]) {
                    updatedEvents[dateKey] = updatedEvents[dateKey].filter(event => event !== eventDescription);
                    if (updatedEvents[dateKey].length === 0) {
                        delete updatedEvents[dateKey];
                    }
                }
                return updatedEvents;
            });
        } catch (error) {
            console.error('Error deleting event:', error);
        }
    };

    useEffect(() => {
        document.title = "TimeCoach | Calendar";
        if (!isLoggedIn) {
            navigate('/login');
        }
        if (effectRan.current === false) {
            const fetchEvents = async () => {
                try {
                    const response = await axios.get(`${API_URL}/api/events/${UserID}`);
                    if (Array.isArray(response.data)) {
                        const fetchedEvents = response.data.reduce((acc, event) => {
                            const dateKey = event.date.split('T')[0];
                            if (!acc[dateKey]) {
                                acc[dateKey] = [];
                            }
                            acc[dateKey].push(event.event);
                            return acc;
                        }, {});
                        console.log("fetchedEvents", fetchedEvents);
                        setEvents(fetchedEvents);
                    } else {
                        console.error('Unexpected API response format:', response.data);
                    }
                } catch (error) {
                    console.error('Error fetching events:', error);
                }
            };
            fetchEvents();
        }
        effectRan.current = true;
        
        const handleClickOutside = (event) => {
            if (dropDownRef.current && !dropDownRef.current.contains(event.target) && event.target !== profileIconRef.current) {
                setIsDropDownVisible(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isLoggedIn, navigate]);   

    return (
        <>
            <div className="top-row">
                <div className="profile-icon" onClick={toggleDropDown}>
                    <img ref={profileIconRef} src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="profile" />
                </div>
                {isDropDownVisible && (
                    <div ref={dropDownRef} className="dropdown-content">
                        <p>{userName}</p>
                        <p>{Email}</p>  
                        <Link to="/login" onClick={handleLogOut}>Logout</Link>
                    </div>
                )}
                <FontAwesomeIcon icon={faAnglesLeft} className="back-icon" onClick={backToDashboard} />
            </div>
            
            {/* Calendar Component */}
            <div className="calendar-container">
                <div className="calendar-header">
                    <button 
                        onClick={handlePrevMonth} 
                        className="calendar-button"
                        disabled={isPrevMonthDisabled()}
                    >
                        Prev
                    </button>
                    <h2 className="calendar-title">{monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}</h2>
                    <button 
                        onClick={handleNextMonth} 
                        className="calendar-button"
                        disabled={isNextMonthDisabled()}
                    >
                        Next
                    </button>
                </div>
                <div className="calendar-grid">
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                        <div key={day} className="calendar-day calendar-day-header">{day}</div>
                    ))}
                    {generateCalendarDays().map((day, index) => (
                        <div
                            key={index}
                            className={`calendar-day ${day && isWithinRange(day) ? 'calendar-day-within-range' : 'calendar-day-outside-range'} ${day && events[day.toISOString().split('T')[0]] ? 'calendar-day-with-event' : ''}`}
                            onClick={() => day && handleDateClick(day)}
                        >
                            {day ? day.getDate() : ''}
                        </div>
                    ))}
                </div>
                {showAddEventForm && selectedDate && (
                    <div className="add-event-form">
                        <h3 className="add-event-title">Add Event for {selectedDate.toDateString()}</h3>
                        <input
                            type="text"
                            value={newEvent}
                            onChange={(e) => setNewEvent(e.target.value)}
                            className="add-event-input"
                            placeholder="Enter event"
                        />
                        <button onClick={handleAddEvent} className="add-event-button">Add Event</button>
                    </div>
                )}
                {selectedDate && events[selectedDate.toISOString().split('T')[0]] && (
                    <Alert title={`Events for ${selectedDate.toDateString()}:`}>
                        <ul className="event-list">
                            {events[selectedDate.toISOString().split('T')[0]].map((event, index) => (
                                <li key={index} className = "event-item">{event}<span className = "remove-event" onClick={() => removeEvent(selectedDate, event)}>x</span></li>
                            ))}
                        </ul>
                    </Alert>
                )}
            </div>
        </>
    )
}