// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-danger {
    color: #dc3545 !important; /* Red color */
      font-size: 0.9em;
      margin-top: -0.2em;
      white-space: pre-line;
  }
`, "",{"version":3,"sources":["webpack://./src/components/authentication/authentication.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,cAAc;MACvC,gBAAgB;MAChB,kBAAkB;MAClB,qBAAqB;EACzB","sourcesContent":[".text-danger {\n    color: #dc3545 !important; /* Red color */\n      font-size: 0.9em;\n      margin-top: -0.2em;\n      white-space: pre-line;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
